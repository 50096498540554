body.JoinOrgPopup-open {
  overflow: hidden;
}

body.JoinOrgPopup-open .JoinOrgPopup {
  overflow-y: auto;
}

.JoinOrgPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2;
}

.JoinOrgPopup-main {
  position: fixed;
  background: white;
  width: 59%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px #000000;
  padding: 20px;
}

.JoinOrgClose-btn {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 35px;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
}
.JoinOrgClosePopup-Heading {
  color: #000;
  text-align: center;
  font-family: 'Poppins';
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  padding-bottom: 15px;
}
.JoinOrgClosePopup-SubHeading {
  color: #000;
  font-family: 'Lato';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 15px;
}
.JoinOrg-orgsCon {
  gap: 20px;
  margin-top: 20px;
  height: 250px;
  overflow: scroll;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.JoinOrg-orgsRow {
  display: flex;
  margin: 0 10px;
  gap: 20px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 15px 15px;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
}
.JoinOrg-LogoImg {
  width: 10%;
  height: auto;
}
.JoinOrg-orgsRowLeft {
  display: flex;
  gap: 200px;
  align-items: center;
  justify-content: space-between;
}
.JoinOrg-orgsName {
  color: #000;
  font-family: 'Poppins';
  width: 200px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.JoinOrg-orgsCreator {
  color: rgba(0, 0, 0, 0.5);
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.JoinOrg-orgsBtnDiv {
  display: flex;
  gap: 20px;
}
.JoinOrg-orgsViewBtn {
  border-radius: 15px;
  background: #93a9fa;
  color: #fff;
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
  width: 150px;
  height: 45px;
}
.JoinOrg-orgsRequestBtn {
  width: 205px;
  height: 45px;
  height: auto;
  border-radius: 15px;
  background: #93a9fa;
  color: #fff;
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
}

@media only screen and (max-width: 1260px) {
  .JoinOrgPopup-main {
    width: 70%;
  }
  .JoinOrgClosePopup-Heading {
    font-size: 25px;
  }
  .JoinOrgClosePopup-SubHeading {
    font-size: 15px;
  }

  .JoinOrg-orgsRowLeft {
    gap: 100px;
  }

  .JoinOrg-orgsViewBtn {
    font-size: 15px;
    width: 135px;
    height: 45px;
  }
  .JoinOrg-orgsRequestBtn {
    font-size: 15px;
    width: 185px;
    height: 45px;
  }
}
@media only screen and (max-width: 1064px) {
  .JoinOrg-orgsRowLeft {
    gap: 0px;
    flex-direction: column;
    justify-content: left;
  }
  .JoinOrg-orgsRow {
    margin: 0 10px;
    gap: 10px;
    justify-content: left;
    margin: 15px 15px;
    padding: 15px 10px;
  }
  .JoinOrgmidddle {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
  .JoinOrg-orgsBtnDiv {
    display: flex;
    gap: 10px;
    flex-direction: column;
    padding-top: 10px;
  }
  .JoinOrg-orgsViewBtn {
    font-size: 13px;
    justify-content: center;
    height: 35px;
  }
  .JoinOrg-orgsRequestBtn {
    font-size: 13px;
    width: 166px;
    height: 35px;
  }
  .JoinOrg-orgsName {
    width: 150px;
  }
  .JoinOrg-LogoImg {
    width: 25%;
  }
}
