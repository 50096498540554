body.View-open {
  overflow: hidden;
}

body.View-open .View {
  overflow-y: auto;
}

.View {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10000;
}

.View-main {
  position: fixed;
  background: white;
  width: 800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px #3b3b3b;
  padding: 22px;
  z-index: 3;
  overflow-y: hidden;
  margin-left: 100px;
}
.restrictionPageImage {
  width: 400px;
  height: auto;
}
.View-main-withoutSidebar {
  position: fixed;
  background: white;
  width: 800px;
  max-height: 70vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px #3b3b3b;
  padding: 22px;
  z-index: 3;
  overflow-y: hidden;
}
.View-main-restricted {
  position: fixed;
  background: white;
  width: 700px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px #3b3b3b;
  padding: 22px;
  z-index: 3;
  overflow-y: hidden;
}

.View-btn {
  position: absolute;
  top: 0px;
  right: 5px;
  font-size: 32px;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
}
.viewFirstRow {
  display: flex;
  gap: 20px;
}
.viewFirstRow-logo {
  padding: 30px;
  border-radius: 20px;
  background: #f7f7f7;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  justify-content: center;
  align-items: center;
}
.viewFirstRow-logoImg {
  width: 100px;
  height: auto;
}
.viewFirstRow-info {
  padding: 15px;
  border-radius: 20px;
  background: #f7f7f7;
  display: flex;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: 80%;
  justify-content: space-between;
}

.viewFirstRow-info1Head {
  color: #000;
  font-family: 'Poppins';
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.viewFirstRow-info1Head > span {
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 10px;
}
.viewFirstRow-info1Desc {
  color: #000;
  font-family: 'Lato';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 36ch;
  text-overflow: ellipsis;
}
.viewFirstRow-info2 {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.viewFirstRow-info2 > p {
  color: #000;
  font-family: 'Lato';
  font-size: 16px;
  padding-top: 5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.viewFirstRow-infoDiv1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.viewSecondRow {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.viewSecondRow-orgDetails {
  padding: 15px;
  border-radius: 20px;
  background: #f7f7f7;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.viewSecondRow-orgDetails1 {
  display: flex;
  gap: 10px;
}
.viewSecondRow-orgDetailsUser {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px 27.5px;
  text-align: center;
}
.viewSecondRow-orgDetailsUser > p {
  color: #000;
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.viewSecondRow-orgDetailsUser > span {
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.viewSecondRow-orgDetailsMembers > p {
  color: #000;
  font-family: 'Poppins';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
}
.initials-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #354150;
  color: #fff;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.02em;
  border-radius: 50%;
  text-align: center;
  text-transform: uppercase;
}
.initials-container-pending {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: rgba(226, 232, 255, 1);
  border-radius: 50px;
  text-transform: capitalize;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: rgba(62, 67, 75, 0.9);
}
.viewSecondRow-orgCoursesDiv {
  padding: 0px;
  border-radius: 20px;
  width: 100%;
}
.viewSecondRow-orgCourses {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
}

.viewSecondRow-orgCoursesCont {
  display: flex;
  gap: 15px;
  width: 99%;
  height: auto;
  overflow-x: scroll;
}
.viewThirdRow {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.viewThirdRow-block1 {
  border-radius: 15px;
  background: #fda53d;
  padding: 20px 15px;
  width: 29%;
  height: auto;
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
  gap: 20px;
}

.viewThirdRow-blockP {
  color: #000;
  font-family: 'Poppins';
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.viewThirdRow-blockText {
  display: flex;
  align-items: baseline;
  gap: 20px;
  justify-content: center;
}
.viewThirdRow-blockText > p {
  color: #000;
  font-family: 'Poppins';
  font-size: 45px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.viewThirdRow-blockText > span {
  color: #000;
  font-family: 'Poppins';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.viewFirstRowPhone {
  display: none;
}
.view-RequestToJoinBtnDiv {
  display: flex;
  justify-content: right;
  margin-top: 20px;
}
.orgLogo-initials-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background-color: #354150;
  color: #fff;
  font-family: 'Poppins';
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 50%;
  text-align: center;
  text-transform: capitalize;
}
@media only screen and (max-width: 1130px) and (min-width: 1065px) {
  .View-main {
    overflow-y: auto;
    max-height: 90vh;
    margin-left: 100px;
    left: 47%;
  }
  .View-main-withoutSidebar {
    overflow-y: auto;
  }
}
@media only screen and (max-width: 1065px) {
  .View-main {
    overflow-y: auto;
    max-height: 90vh;
    margin-left: 100px;
    left: 47%;
    width: 50%;
  }
  .View-main-withoutSidebar {
    overflow-y: auto;
    max-height: 70vh;
    width: 92%;
  }
  .View-main-restricted {
    width: 80%;
  }
  .viewFirstRow {
    display: none;
  }
  .viewFirstRowPhone {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    border-radius: 20px;
    background: #f7f7f7;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  .viewFirstRowPhone-1 {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .viewFirstRowPhone-2 {
    display: flex;
    justify-content: space-between;
  }
  .viewFirstRow-info1Head {
    margin-bottom: 0px;
  }
  .viewFirstRow-info1Desc {
    font-size: 14px;
    padding-top: 10px;
  }

  .viewFirstRow-info2 {
    padding: 10px 10px;
  }
  .viewFirstRow-info2Head {
    font-size: 16px;
    gap: 5px;
    margin-bottom: 5px;
  }
  .viewFirstRow-info2 > p {
    font-size: 13px;
  }

  .viewSecondRow {
    flex-direction: column;
    gap: 20px;
  }

  .viewSecondRow-orgDetails1 {
    justify-content: space-between;
  }
  .viewSecondRow-orgDetailsUser {
    padding: 12px 15px;
  }

  .viewSecondRow-orgDetailsUser > p {
    font-size: 12px;
  }
  .viewSecondRow-orgDetailsUser > span {
    font-size: 15px;
  }

  .viewSecondRow-orgDetailsDate > p {
    font-size: 12px;
  }
  .viewSecondRow-orgDetailsDate > span {
    font-size: 15px;
  }

  .viewSecondRow-orgDetailsMembers > p {
    font-size: 20px;
  }
  .viewSecondRow-orgCoursesDiv {
    width: 100%;
  }
  .viewThirdRow {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  .viewThirdRow-block1 {
    padding: 10px 10px;
    width: 150px;
  }
  .viewThirdRow-blockP {
    font-size: 13px;
  }
  .viewThirdRow-blockText {
    gap: 3px;
  }
  .viewThirdRow-blockText > p {
    font-size: 20px;
  }
  .viewThirdRow-blockText > span {
    font-size: 16px;
  }
  .viewFirstRow-logoImg {
    width: 70px;
    height: auto;
  }
  .orgLogo-initials-container {
    width: 50px;
    height: 50px;
    font-size: 25px;
  }
}
@media only screen and (max-width: 600px) {
  .View-main {
    overflow-y: auto;
    max-height: 80vh;
    margin-left: 0px;
    left: 50%;
    width: 70%;
    top: 55%;
  }
  .View-main-withoutSidebar {
    overflow-y: auto;
    max-height: 70vh;
    margin-left: 0px;
    left: 50%;
    width: 70%;
    top: 50%;
  }
  .restrictionPageImage {
    width: 200px;
    height: auto;
  }
  .View-main-restricted {
    width: 70%;
  }
  .viewFirstRow {
    display: none;
  }
  .viewFirstRowPhone {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    border-radius: 20px;
    background: #f7f7f7;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  .viewFirstRowPhone-1 {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .viewFirstRowPhone-2 {
    display: flex;
    justify-content: space-between;
  }
  .viewFirstRow-info1Head {
    margin-bottom: 0px;
  }
  .viewFirstRow-info1Desc {
    font-size: 14px;
    padding-top: 10px;
  }

  .viewFirstRow-info2 {
    padding: 10px 10px;
  }
  .viewFirstRow-info2Head {
    font-size: 16px;
    gap: 5px;
    margin-bottom: 5px;
  }
  .viewFirstRow-info2 > p {
    font-size: 13px;
  }

  .viewSecondRow {
    flex-direction: column;
    gap: 20px;
  }

  .viewSecondRow-orgDetails1 {
    justify-content: space-between;
  }
  .viewSecondRow-orgDetailsUser {
    padding: 12px 15px;
  }

  .viewSecondRow-orgDetailsUser > p {
    font-size: 12px;
  }
  .viewSecondRow-orgDetailsUser > span {
    font-size: 15px;
  }

  .viewSecondRow-orgDetailsDate > p {
    font-size: 12px;
  }
  .viewSecondRow-orgDetailsDate > span {
    font-size: 15px;
  }

  .viewSecondRow-orgDetailsMembers > p {
    font-size: 20px;
  }
  .viewSecondRow-orgCoursesDiv {
    width: 100%;
  }
  .viewThirdRow {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  .viewThirdRow-block1 {
    padding: 10px 10px;
    width: 150px;
  }
  .viewThirdRow-blockP {
    font-size: 13px;
  }
  .viewThirdRow-blockText {
    gap: 3px;
  }
  .viewThirdRow-blockText > p {
    font-size: 20px;
  }
  .viewThirdRow-blockText > span {
    font-size: 16px;
  }
  .viewFirstRow-logoImg {
    width: 70px;
    height: auto;
  }
  .orgLogo-initials-container {
    width: 50px;
    height: 50px;
    font-size: 25px;
  }
}
